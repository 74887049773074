import React from 'react';
import { Link } from 'gatsby';
import { Container } from './mobileNav.css';
import posed from 'react-pose';

const AnimatedContainer = posed.div({
  enter: {
    opacity: '100',
    zIndex: '100',
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    opacity: '0',
    zIndex: '-55',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const removeScrollLock = () => {
  document.body.classList.remove('mobile-menu-open');
};

const MobileNav = () => (
  <AnimatedContainer>
    <Container>
      <ul>
        <li>
          <Link to="/projects" onClick={removeScrollLock}>
            Projects
          </Link>
        </li>
        <li>
          <Link to="/photos" onClick={removeScrollLock}>
            Stuff
          </Link>
        </li>
        <li>
          <Link to="/about" onClick={removeScrollLock}>
            About
          </Link>
        </li>
        <li>
          <Link to="/contact" onClick={removeScrollLock}>
            Contact
          </Link>
        </li>
      </ul>
    </Container>
  </AnimatedContainer>
);

export default MobileNav;
