import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import posed from 'react-pose';
import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';

import { Container, HeaderTitle, HeaderSubtitle } from './header.css';
import Nav from './nav/nav';
import MobileNav from '../mobileNav/mobileNav';

const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const NavButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 2rem;
  height: 2rem;

  &:hover {
    cursor: pointer;
  }

  div {
    display: block;
    width: 2rem;
    height: 0.25rem;
    background: #fff;
  }

  ${MEDIA.MIN_TABLET`
    display: none;
  `};
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  toggleMenu() {
    this.state.isOpen
      ? document.body.classList.remove('mobile-menu-open')
      : document.body.classList.add('mobile-menu-open');

    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    return (
      <AnimatedContainer>
        <Container>
          <Link to="/">
            <HeaderTitle>{this.props.title}</HeaderTitle>
            <HeaderSubtitle>{this.props.subtitle}</HeaderSubtitle>
          </Link>

          <Nav />
          <NavButton onClick={() => this.toggleMenu()}>
            <div />
            <div />
            <div />
          </NavButton>
        </Container>
        {this.state.isOpen && <MobileNav />}
      </AnimatedContainer>
    );
  }
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default Header;
