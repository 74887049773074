import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';

export const Container = styled.div`
  padding: 2.5rem;
  max-width: auto;

  ${MEDIA.MIN_TABLET`
    max-width: 64rem;
    margin: 0 auto;
  `};
`;
