import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 88;
  width: 100vw;
  height: 100vh;
  background-color: rgba(13, 18, 21, 1);
  background-image: linear-gradient(
    180deg,
    rgba(23, 36, 44, 1) 0%,
    rgba(13, 18, 21, 1) 100%
  );

  ${MEDIA.MIN_TABLET`
    display: none;
  `};

  ul {
    display: flex;
    flex-direction: column;
    width: 100%;

    li a {
      display: block;
      padding: 1.5rem 1rem;
      margin-bottom: 1rem;
      font-size: 2rem;
      font-family: "Open Sans Condensed";
      font-weight: 300;
      letter-spacing: 1.8px;
      line-height: 1;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      color: #f1f6f7;
    }
`;
