import { createGlobalStyle } from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import 'typeface-poppins';
import 'typeface-open-sans-condensed';

export default createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html {
    width: 100%;
    height: 100%;
    font-size: 100%;
  }

  body {
    position: relative;
    width: 100%;
    height: 100%;
    font-family: 'Poppins',"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: 300;
    line-height: 1;
    font-size: 16px;
    color: #F1F6F7;
    /* background: rgb(25,33,37);
    background: linear-gradient(135deg, rgba(25,33,37,1) 0%, rgba(12,14,14,1) 100%); */

    /* background: rgb(23,44,44);
    background: linear-gradient(135deg, rgba(23,44,44,1) 0%, rgba(13,21,21,1) 100%); */

    background-color: rgba(13,18,21,1);
    background-image: linear-gradient(180deg, rgba(23,36,44,1) 0%, rgba(13,18,21,1) 100%);
    background-repeat: no-repeat;
    background-size: cover;

    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
  }

  .mobile-menu-open {
    position: fixed;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  video {
    max-width: 100%;
  }

  p, span, a {
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5;
    color: #F1F6F7;
    letter-spacing: 0;
    margin-bottom: 2rem;
  }

  a {
    color: #eaca27;
  }

  h1 {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 1.4;
    color: #DDF3F8;
    letter-spacing: 0;
    text-shadow: 0 2px 8px rgba(0,0,0,0.24);
    margin-bottom: 1.5rem;

    ${MEDIA.MIN_TABLET`
      font-size: 2.375rem;
      color: #DDF3F8;
      letter-spacing: 0;
    `};
  }
`;
