import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';
import { Container } from './section.css';

export const Horizontal = styled.div`
  display: flex;
  flex-direction: column;

  ${MEDIA.MIN_TABLET`
    flex-direction: row;
  `}
`;

export const Column = styled.div`
  flex-basis: 50%;

  ${MEDIA.MIN_TABLET`
    margin-right: 1rem;

    & + & {
      margin-left: 1rem;
      margin-right: 0;
    }
  `}
`;

const Section = ({ children }) => <Container>{children}</Container>;

Section.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Section;
