import styled from 'styled-components';

export const Container = styled.header`
  position: relative;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    color: #757575;
    transition: color 0.2s ease;
    text-decoration: none;
    margin: 0;

    &:hover {
      color: inherit;
    }
  }
`;

export const HeaderTitle = styled.span`
  display: block;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 1.625rem;
  color: #f1f6f7;
  letter-spacing: 0;
  line-height: 1;
  text-shadow: 0 2px 8px rgba(0, 0, 0, 0.24);
  margin: 0;
`;

export const HeaderSubtitle = styled.span`
  display: block;
  font-family: 'Open Sans Condensed';
  font-weight: 300;
  font-size: 1.25rem;
  color: #eaca27;
  letter-spacing: 8.2px;
  line-height: 1.5rem;
  text-transform: uppercase;
  margin: 0;
`;
