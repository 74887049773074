import React from 'react';
import { Link } from 'gatsby';
import { NavList } from './nav.css';

const Nav = () => (
  <>
    <NavList>
      <li>
        <Link to="/projects">Projects</Link>
      </li>
      <li>
        <Link to="/photos">Photos</Link>
      </li>
      <li>
        <Link to="/about">About</Link>
      </li>
      <li>
        <Link to="/contact">Contact</Link>
      </li>
    </NavList>
  </>
);

export default Nav;
