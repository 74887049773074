import styled from 'styled-components';
import MEDIA from '../../../helpers/mediaTemplates';

export const Container = styled.nav``;

export const NavList = styled.ul`
  display: none;

  ${MEDIA.MIN_TABLET`
    display: flex;
    flex-direction: row;

    li a {
      position: relative;
      font-size: 1.25rem;
      text-align: left;
      font-family: 'Open Sans Condensed';
      letter-spacing: 1.8px;
      line-height: 1;
      text-transform: uppercase;
      color: #f1f6f7;

      &:hover, &[aria-current="page"] {
        &:after {
          position: absolute;
          left: calc(50% - 1rem);
          right 25%;
          bottom: -1rem;
          content: '';
          width: 2rem;
          height: .25rem;
          background-color: #eaca27;
        }
      }
    }

    li + li {
      margin-left: 2rem;
    }
  `};
`;
